import React from 'react'
import Layout from '../components/layout'
import * as S from '../styles/pages/404Style'
import * as G from '../styles/globalStyle'

export default function Contact() {
  return (
    <Layout>
      <S.ErrorContainer>
        <S.ErrorHeader>Strona nie istnieje</S.ErrorHeader>
        <G.LinkButton to="/">wróć do strony głównej</G.LinkButton>
      </S.ErrorContainer>
    </Layout>
  )
}
