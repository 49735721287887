import styled from 'styled-components'
import * as G from '../globalStyle'

export const ErrorContainer = styled(G.Container)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 385px);
  background: url('../../../hands.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  @media (min-width: ${props => props.theme.size.large}) {
    min-height: calc(100vh - 520px);
  }
`

export const ErrorHeader = styled(G.MainHeader)`
  margin-top: 30px;
  margin-bottom: 80px;
`
